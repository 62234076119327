import React from 'react';
import Iframe from 'react-iframe';

export const Styleguide: React.FC<{
  src: string;
}> = ({ src }) => (
  <div style={{ padding: '20px' }}>
    <Iframe
      id="designgapp"
      title="Styleguide"
      width="100%"
      height="1000"
      frameBorder={0}
      url={src}
      allowFullScreen
    />
  </div>
);
