import './App.css';
import { Link } from 'react-router-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Styleguide } from './components/StyleGuide';
import { useState } from 'react';
const edits = {
  0: process.env.REACT_APP_HAKKEI_STYLEGUIDE_EDIT,
  1: process.env.REACT_APP_RIVEN_STYLEGUIDE_EDIT,
  2: process.env.REACT_APP_USBNEX_STYLEGUIDE_EDIT,
};
console.log(edits);
function App() {
  const history = createBrowserHistory();
  const [editSrc, setEditSrc] = useState<string | undefined>(edits[0]);

  const EditButton = () => {
    const open = () => window.open(`${process.env.REACT_APP_EDIT_BASE}/${editSrc}`);
    return <input type="button" onClick={() => open()} value="Edit" />;
  };

  return (
    <Router history={history}>
      <div className="App">
        <h1>Styleguides</h1>
        <nav>
          <ul>
            <li
              id={process.env.REACT_APP_HAKKEI_STYLEGUIDE_EDIT}
              onClick={() => setEditSrc(edits[0])}
            >
              <Link to="/">hakkei.co</Link>
            </li>
            <li
              id={process.env.REACT_APP_RIVEN_STYLEGUIDE_EDIT}
              onClick={() => setEditSrc(edits[1])}
            >
              <Link to="/riven-styleguide">rivenkeyboard.com</Link>
            </li>
            <li
              id={process.env.REACT_APP_USBNEX_STYLEGUIDE_EDIT}
              onClick={() => setEditSrc(edits[2])}
            >
              <Link to="/usbnex-styleguide">usbnex.com</Link>
            </li>
          </ul>
        </nav>
        <EditButton />
        <Switch>
          <Route path="/riven-styleguide">
            <Styleguide src="https://www.designgapp.com/s/CMtgZHW_S" />
          </Route>
          <Route exact path="/usbnex-styleguide">
            <Styleguide src="https://www.designgapp.com/s/keIdNN57O" />
          </Route>
          <Route exact path="/">
            <Styleguide src="https://www.designgapp.com/s/Khz52vqET" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
